.top_select[data-v-45a25c07] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.div_p[data-v-45a25c07] {
  font-size: 13px;
  line-height: 24px;
  height: 24px;
}
.div_p i[data-v-45a25c07] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
.div_p_bank[data-v-45a25c07] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div_p_bank .bank_left[data-v-45a25c07] {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.item_icon[data-v-45a25c07] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-45a25c07] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
}
.item_icon .iconfont icon-cuowu[data-v-45a25c07] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-45a25c07] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-45a25c07] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-45a25c07] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-45a25c07] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-45a25c07] {
  color: #e6a23c;
}
.item_icon p[data-v-45a25c07] {
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  color: #333;
  margin-left: 4px;
}
.el-radio[data-v-45a25c07] {
  margin-right: 12px;
}
.elDrawerDetail .el-drawer__body[data-v-45a25c07] {
  padding-top: 0px;
}
.content_data[data-v-45a25c07] {
  background-color: #d8ecff;
}
.content_data .each[data-v-45a25c07] {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.content_data .each span[data-v-45a25c07] {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.content_data .icon_bg[data-v-45a25c07] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.content_data .iconfont[data-v-45a25c07] {
  font-size: 18px;
  color: #17a2b8;
}
.collect_bg[data-v-45a25c07] {
  background-color: rgba(231, 231, 231, 0.3490196078);
  padding: 10px;
  margin-bottom: 20px;
}
.collect_bg .footer[data-v-45a25c07] {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
.jdcj[data-v-45a25c07] {
  color: red;
  cursor: pointer;
  margin-left: 4px;
}
.icon-wenhao[data-v-45a25c07] {
  color: red;
  cursor: pointer;
}